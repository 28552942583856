<template>
  <v-card class="mt-5 mx-auto" max-width="800">
    <v-toolbar elevation="5" color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">
        <v-toolbar-title>{{ isNew() ? "Новый" : "Редактировать" }}</v-toolbar-title>
      </v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-3">
      <v-col cols="6">
        <v-text-field v-model="item.name"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Название"/>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="item.max_distance"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Максимальная дистанция"/>
      </v-col>
      <v-col cols="12">
        <v-btn fab rounded small>
          <v-icon large color="primary" @click="add">mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="row justify-space-around align-center d-flex" v-for="(f, index) in item.fields"
             :key="index">
        <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters d-flex align-center">
          <v-text-field v-model="f.key"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Ключ"/>
        </v-col>
        <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters d-flex align-center">
          <v-text-field v-model="f.label"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Отображение"/>
        </v-col>
        <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters d-flex align-center">
          <v-autocomplete v-model="f.type"
                          :items="types"
                          dense
                          rounded
                          outlined
                          hide-details
                          label="Тип"/>
        </v-col>
        <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-center d-flex align-center">
          <v-btn fab rounded small>
            <v-icon large color="error" @click="remove(f)">mdi-minus-circle</v-icon>
          </v-btn>
        </v-col>
      </v-col>
      <v-col v-if="!isNew()" cols="12" class="row no-gutters align-center d-flex">
        <div v-if="item.icon">
          <div>Изображение</div>
          <v-img :src="item.icon"
                 height="150px"
                 width="150px"
                 @click="$refs.image.click()"/>
        </div>
        <div v-show="!item.icon">
          <input type="file"
                 id="image"
                 ref="image"
                 accept="image/*"
                 v-on:change="handleFileUpload(item.id)"/>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-space-around pb-3">
      <v-btn outlined rounded elevation="3" color="error" class="mr-2" @click="close">Отменить</v-btn>
      <v-btn outlined rounded elevation="3" color="primary" @click="save">Сохранить</v-btn>
    </v-row>
  </v-card>
</template>

<script>

import {uuid} from "uuidv4";
import axios from "../services/axios";

export default {
  data: () => ({
    item: {
      id: uuid.Nil,
      name: "",
      icon: "",
      fields: []
    },
    types: [
      {text: "строка", value: 'string'},
      {text: "число", value: 'number'},
      {text: "дата", value: 'date'}
    ]
  }),

  computed: {
    role() {
      return this.$store.getters["auth/role"];
    },
  },

  created() {
    let id = this.$route.params.id;

    if (id !== uuid.Nil) {
      axios.send({
        url: "/vehicle/types/" + id,
        method: "GET"
      }).then(resp => {
        this.item = resp.data;
      });
    }
  },

  methods: {
    isNew() {
      if (!this.item.id) {
        return true
      }
      return !this.item.id === uuid.Nil;
    },

    add() {
      this.item.fields.push({key: "", label: "", type: "string"})
    },

    remove(item) {
      this.item.fields = this.item.fields.filter(i => {
        return item.key !== i.key;
      });
    },

    handleFileUpload(id) {
      let file = this.$refs.image.files[0];
      let formData = new FormData();
      formData.append("image", file);

      axios.send({
        url: "/vehicle/types/" + id,
        method: "POST",
        data: formData,
        headers: {"Content-Type": "multipart/form-data"}
      }).then(resp => {
        this.item.icon = resp.data;
      });
    },


    save() {
      this.item.max_distance = parseFloat(this.item.max_distance)
      if (this.item.id === uuid.Nil) {
        axios.send({
          url: "/vehicle/types",
          method: "POST",
          data: this.item
        }).then(() => {
          this.close();
        });
      } else {
        axios.send({
          url: "/vehicle/types",
          method: "PUT",
          data: this.item
        }).then(() => {
          this.close();
        });
      }
    },

    close() {
      this.item.id = uuid.Nil;
      this.item.name = "";
      this.item.icon = "";
      this.item.fields = "";

      this.$router.push({name: 'vehicleType'})
    }
  }
};
</script>
